import ProjectDetail from "../../../../src/components/ProjectDetail";
import Heading from "../../../../src/components/Heading";
import { DeviceFrameset } from "../../../../src/components/DeviceFrameSet/DeviceFrameset";
import { ParallaxRouteUpdate } from "../../../../src/components/parallax-route-update";
import { Parallax, ParallaxBanner, useParallaxController } from "react-scroll-parallax";
import * as React from 'react';
export default {
  ProjectDetail,
  Heading,
  DeviceFrameset,
  ParallaxRouteUpdate,
  Parallax,
  ParallaxBanner,
  useParallaxController,
  React
};