import * as React from "react"

// const lineColor = '#ddd';

function GridBg(props) {
  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        vectorEffect="non-scaling-stroke"
        stroke="currentColor"
        fill="none"
        d="M50 100L0 0l100 100L50 0v100L100 0 0 100 50 0 0 50l50 50 50-50L50 0"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="0%"
        y1="6.25%"
        x2="100%"
        y2="6.25%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="0%"
        y1="3.125%"
        x2="100%"
        y2="3.125%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="0%"
        y1="12.5%"
        x2="100%"
        y2="12.5%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="0%"
        y1="25%"
        x2="100%"
        y2="25%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="0%"
        y1="75%"
        x2="100%"
        y2="75%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="0%"
        y1="87.5%"
        x2="100%"
        y2="87.5%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="0%"
        y1="93.75%"
        x2="100%"
        y2="93.75%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="0%"
        y1="96.875%"
        x2="100%"
        y2="96.875%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="0%"
        y1="50%"
        x2="100%"
        y2="50%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="25%"
        y1="0%"
        x2="25%"
        y2="100%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="12.5%"
        y1="0%"
        x2="12.5%"
        y2="100%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="3.125%"
        y1="0%"
        x2="3.125%"
        y2="100%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="96.875%"
        y1="0%"
        x2="96.875%"
        y2="100%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="6.25%"
        y1="0%"
        x2="6.25%"
        y2="100%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="93.75%"
        y1="0%"
        x2="93.75%"
        y2="100%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="87.5%"
        y1="0%"
        x2="87.5%"
        y2="100%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="33.333%"
        y1="0%"
        x2="33.333%"
        y2="100%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="66.667%"
        y1="0%"
        x2="66.667%"
        y2="100%"
      />
      <line
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        x1="75%"
        y1="0%"
        x2="75%"
        y2="100%"
      />
    </svg>
  )
}

export default GridBg
